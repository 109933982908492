@-webkit-keyframes RouterLoaderAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes RouterLoaderAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes RouterLoaderAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  display: flex;
  justify-content: left;
  align-items: left;
  animation: RouterLoaderAnimation 1.3s;
  animation-fill-mode: forwards;
}

.Spinner {
  display: block;
}

.SpinnerEl {
  border-width: 2px !important;
  width: 1rem !important;
  height: 1rem !important;
}

:global(.app-loading) .Container {
  display: none;
}
