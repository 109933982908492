.form-client-project-selection {
  select.client_project {
    border-radius: 5px;
    width: 100%;
  }

  .form-check-input {
    margin-top: 5px;
  }
}

/* styles.css */
.dropzone {
  width: 100%;
  height: 20%;
  border: 1px solid #d3d3d3;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
}
td.unread {
  font-weight: bold;
}
tr.unread {
  cursor: pointer;
  font-size: small;
}
select.red {
  border-color: red;
  color: red;
}

.email-card {
  max-height: 19rem;
}
table.email-dashboard td,
table.email-dashboard th {
  padding: 0.25rem 0 0.25rem 0.08rem !important;
}
table.email-dashboard td,
div.left-td {
  text-align: initial;
}
table.email-dashboard td i {
  margin-right: 0.3rem;
}
table.email-dashboard td.td-date {
  text-align: center;
}
.sammy-nowrap-2 {
  max-width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-table div.rt-td {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

div.viewer .btn-group {
  vertical-align: top !important;
}

.email-view-container {
  .synced_on {
    margin-top: -3.7em;
  }
}
div.email-alert .alert {
  margin-bottom: 1px;
  height: 35px;
  line-height: 35px;
  padding: 0px 15px;
}
iframe#email-viewver-iframe {
  width: 100%;
  // height: 500px;
  border: none;
  overflow-y: hidden;
}

.email-list .card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: 0.125rem;
  margin-left: 0 !important;
  border-bottom: 0;
}
.email-list .nav-tabs .nav-link:not(.active),
.nav-pills .nav-link:not(.active) {
  background-color: #f2f3f3;
}
.email-list .nav .nav-link.active {
  font-weight: bold;
  border-bottom-color: #259ddc;
  color: #259ddc;
}

.proserve-table div .rt-table {
  min-height: 7rem;
}

.default-style .ReactTable .rt-th,
.default-style .ReactTable .rt-td {
  padding: 0.625rem !important;
}

.email-metadata {
  .email-address-info {
    font-size: 16px;
  }
}

.only-checkbox input {
  transform: scale(2);
  margin-right: 11px;
}

.form-check-label {
  margin-left: 0.5rem;
}
.email-card {
  padding: 0.2rem !important;
}

.flex-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
