.optionListContainer {
  position:relative !important;
  height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 250px;
}
.searchWrapper .chip {
  // display: -webkit-box;
  // display: -webkit-flex;
  // display: -ms-flexbox;
  // display: flex;
  min-width: 0;
  background-color: hsl(0, 0%, 90%);
  // border-radius: 2px;
  margin: 2px;
  box-sizing: border-box;
  color: black;
  // width: 100%;
  padding: 2px 5px !important;
}
// .multiSelectContainer {
//   width: 450px !important;
// }
// .multiSelectContainer input[type="text"] {
//   width: 450px !important;
// }

.multiSelectContainer ul {
  overflow-y: hidden !important;
}
.multiSelectContainer .highlightOption {
  background-color: transparent;
  color: black;
}
.multiSelectContainer .icon_cancel {
  content: "X";
  height: 10px;
  width: auto;
  color: black;
}
.exactMatch .chip {
  background-color: #38761D;
  color: white;
  font-weight: normal !important;
}

.partialMatch .chip {
  background-color: #FCCD43;
  font-weight: normal !important;
}
.partialMatch .icon_cancel {
  color: black !important;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}


.multiSelectContainer .searchWrapper { 
  padding: 2px !important;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.multiSelectContainer span.chip+input {
  width: 5px !important;
}
.multiSelectContainer span.chip+input:focus {
  width: 100% !important;
}


/*  f**/
span.chip span[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
span.chip  span[data-title]:after {
  content: attr(data-title);
  position: absolute;
  z-index: 9999999999999999999999999999999999;
  top: -1.3em;
  left: 100%;
  padding: 4px 4px 4px 8px;
  color: white;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #666;
  -webkit-box-shadow: 0px 0px 4px #666;
  box-shadow: 0px 0px 4px #666;
  // background-image: -moz-linear-gradient(top, #f0eded, #bfbdbd);
  // background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f0eded), color-stop(1, #bfbdbd));
  // background-image: -webkit-linear-gradient(top, #f0eded, #bfbdbd);
  // background-image: -moz-linear-gradient(top, #f0eded, #bfbdbd);
  // background-image: -ms-linear-gradient(top, #f0eded, #bfbdbd);
  // background-image: -o-linear-gradient(top, #f0eded, #bfbdbd);
  background-color: black;
  opacity: 0; 
  visibility: hidden;
}
span.chip span[data-title] {
  position: relative;
}

.btn-group-select {
  position: relative; 
  display: -ms-inline-flexbox;
  display: inline-flex;
  
}
.middle {
  padding-top: 1rem;
}

label.select ,label.form-check-label{
  cursor: pointer !important;
}

tr.details {
  background-color: #f3f5f8 !important;
}
.css-1nmdiq5-menu {
  z-index: 6 !important;
}

.main-partner {
  display: flex;
  flex-direction: row; 
}
div.checkType {
  font-size: 11px;
}
div.checkType p {
  margin-bottom: 5px !important;
}
label.custom-margin {
  font-size: 0.78125rem !important;
}

table td input[type="checkbox"] {
 cursor: pointer;
}

span.pending-items {
  color: black !important;
}